import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';

export const TextareaControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled, isError }) => (
    <FormControl required={required} disabled={disabled}>
      {label && <FormLabel error={isError}>{label}</FormLabel>}
      <OutlinedInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        multiline
        minRows={3}
        readOnly={readOnly}
        disabled={disabled}
        sx={{ padding: 0 }}
        error={isError}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
);
