import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  RentBuildingsProps,
  RentBuilding,
} from '@features/effector-form/controls/rent-building-controls/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { RentBuildingsControlGate, $data, $pending } from '../model';

export const RentBuildingControl = withControl<RentBuildingsProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    complex = null,
    excludeOptions = [],
    onCustomChange,
    name,
    conditionForChange,
    isError,
  }) => {
    useGate(RentBuildingsControlGate, { complexes: complex });
    const [data, pending] = useUnit([$data, $pending]);

    const key = complex ?? '';
    const options = data[key] ?? [];
    const val = value !== '' ? options?.find((item) => item.id === value) : null;

    const handleChange = (_: unknown, newValue: any) =>
      onCustomChange && conditionForChange
        ? onCustomChange({ value: newValue?.id || null, path: name })
        : setValue(newValue?.id || null);

    const handleFilterOptions = (
      opts: RentBuilding[],
      { inputValue }: { inputValue: string }
    ) => {
      const currentOptions = excludeOptions.length
        ? opts.reduce(
            (acc: RentBuilding[], option) =>
              !excludeOptions.includes(option.id) ? [...acc, option] : acc,
            []
          )
        : opts;

      if (inputValue) {
        return currentOptions.filter((opt) =>
          opt?.label?.toUpperCase().includes(inputValue.toUpperCase())
        );
      }

      return currentOptions;
    };

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          loading={pending}
          filterOptions={handleFilterOptions}
          value={!options ? null : val || null}
          options={options && complex ? options : []}
          onChange={handleChange}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
