import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { data } from '@features/effector-form/controls/rent-payment/model';
import { withControl } from '@features/effector-form/hoc/withControl';

export const RentPaymentControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled, isError }) => {
    const val = value !== '' ? data?.find((item) => item.id === value) : null;

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          value={!data ? null : val || null}
          options={!data ? [] : data}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue?.id);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : params.InputProps.endAdornment,
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
