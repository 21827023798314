import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { PropertyControlGate, propertyQuery } from './model';
import { PropertyControlProps, PropertyId } from './types';

export const PropertiesControl = withControl<PropertyControlProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    buildings,
    placeholder,
    isError,
  }) => {
    useGate(PropertyControlGate);

    const { data, pending } = useUnit(propertyQuery);

    const filterBuildings = () => {
      if (!data) return [];
      if (buildings?.length && data) {
        const filteredData = data.filter((item) => {
          const findedBuild = item.buildings.filter((build) =>
            buildings.find((el) => el.title === build.building)
          );
          return findedBuild.length > 0;
        });
        return filteredData;
      }
      return data;
    };

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          multiple
          loading={pending}
          value={!data ? [] : data.filter((item) => value.indexOf(item.id) > -1)}
          options={filterBuildings()}
          onChange={(event: any, newValue: PropertyId[]) => {
            setValue(newValue.map((item) => item.id));
          }}
          getOptionLabel={(option) => option.title}
          ListboxComponent={Listbox}
          disableClearable
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                placeholder,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
