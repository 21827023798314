import React, { HTMLAttributes } from 'react';
import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  Box,
  FormControl,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  TicketTypeControlGate,
  ticketTypesQuery,
} from '@features/effector-form/controls/ticketType/model';
import {
  StyledTicketTypeContainer,
  StyledTicketTypeNestedIcon,
  StyledTicketTypeSecond,
  StyledTicketTypeThird,
} from '@features/effector-form/controls/ticketType/styled';
import { TicketTypeInstance } from '@features/effector-form/controls/ticketType/types';
import { withControl } from '@features/effector-form/hoc/withControl';

const GroupHeader = styled('div')(({ theme }) => ({
  padding: '4px 10px',
  backgroundColor: theme.palette.grey[50],
}));

export const TicketTypeControl = withControl(
  ({ label, required, readOnly, disabled, setValue, error, value, isError }) => {
    useGate(TicketTypeControlGate);

    const { data, pending } = useUnit(ticketTypesQuery);

    const handleChange = (newValue: TicketTypeInstance['id'] | null, index: number) => {
      const valueArray = value || [];

      if (!newValue) {
        setValue(valueArray.splice(index, 1));
      } else if (index === 0) {
        setValue([newValue]);
      } else if (index === 1) {
        valueArray[1] = newValue;
        valueArray.length = 2;
        setValue(valueArray);
      } else {
        valueArray[2] = newValue;
        setValue(valueArray);
      }
    };

    const getOptionDisabled = (option: TicketTypeInstance) => option.archived;

    const renderOption = (
      renderProps: HTMLAttributes<HTMLLIElement>,
      option: TicketTypeInstance
    ) => (
      <Box
        component="li"
        {...renderProps}
        style={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)', gap: 10 }}
      >
        {option.label}
        {option.archived && <ArchiveOutlinedIcon />}
        {option.paid && <CurrencyRubleIcon />}
      </Box>
    );

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <StyledTicketTypeContainer>
          <Autocomplete
            loading={pending}
            value={!data ? null : data?.find((item) => item.id === value[0])}
            options={!data ? [] : data}
            onChange={(event, newValue) => {
              handleChange(newValue?.id || null, 0);
            }}
            ListboxComponent={Listbox}
            renderOption={renderOption}
            getOptionDisabled={getOptionDisabled}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  readOnly,
                  error: isError,
                  endAdornment: readOnly ? null : (
                    <>
                      {pending && <CircularProgress size={20} color="inherit" />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            readOnly={readOnly}
            disabled={disabled}
          />

          {value && value[0] && (
            <StyledTicketTypeSecond>
              <StyledTicketTypeNestedIcon />
              <Autocomplete
                loading={pending}
                value={!data ? null : data?.find((item) => item.id === value[1])}
                options={!data ? [] : data}
                onChange={(event, newValue) => {
                  handleChange(newValue?.id || null, 1);
                }}
                ListboxComponent={Listbox}
                renderOption={renderOption}
                getOptionDisabled={getOptionDisabled}
                renderInput={(params): JSX.Element => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      readOnly,
                      error: isError,
                      endAdornment: readOnly ? null : (
                        <>
                          {pending && <CircularProgress size={20} color="inherit" />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                readOnly={readOnly}
                disabled={disabled}
              />
            </StyledTicketTypeSecond>
          )}

          {value && value[1] && (
            <StyledTicketTypeThird>
              <StyledTicketTypeNestedIcon />
              <Autocomplete
                loading={pending}
                value={!data ? null : data?.find((item) => item.id === value[2])}
                options={!data ? [] : data}
                onChange={(event, newValue) => {
                  handleChange(newValue?.id || null, 2);
                }}
                ListboxComponent={Listbox}
                renderOption={renderOption}
                getOptionDisabled={getOptionDisabled}
                renderInput={(params): JSX.Element => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      error: isError,
                      readOnly,
                      endAdornment: readOnly ? null : (
                        <>
                          {pending && <CircularProgress size={20} color="inherit" />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                readOnly={readOnly}
                disabled={disabled}
              />
            </StyledTicketTypeThird>
          )}
        </StyledTicketTypeContainer>
      </FormControl>
    );
  }
);
