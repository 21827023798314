import { useIMask } from 'react-imask';
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';

type Props = {
  endAdornment?: JSX.Element;
  placeholder?: string;
};

export const NumberControl = withControl<Props>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    placeholder,
    helperText,
    endAdornment = null,
    isError,
  }) => {
    const { ref } = useIMask(
      { mask: /^\d+$/ },
      {
        onComplete: (data) => {
          if (data !== '') {
            setValue(Number(data));
            return;
          }

          setValue(data);
        },
      }
    );

    return (
      <FormControl required={required && !readOnly} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <OutlinedInput
          inputRef={ref}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          endAdornment={endAdornment}
          placeholder={placeholder}
          error={isError}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
