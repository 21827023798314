import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import { Listbox } from '../../components/listbox';
import { withControl } from '../../hoc/withControl';
import { SmsPassModeGate, smsPassModeQuery } from './model';
import { SmsPassMode } from './types';

type Props = {
  disableClearable?: boolean;
};

export const SmsPassModeControl = withControl<Props>(
  ({
    label,
    value,
    setValue,
    error,
    required,
    readOnly,
    disabled,
    isError,
    disableClearable,
  }) => {
    useGate(SmsPassModeGate);
    const { data, pending } = useUnit(smsPassModeQuery);

    const findedValue =
      value !== ''
        ? data?.find(
            (item: SmsPassMode) =>
              item.id === (typeof value === 'object' ? value?.id : value)
          )
        : null;

    const handleChange = (_: unknown, newValue: any) => setValue(newValue || null);

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          disableClearable={disableClearable}
          loading={pending}
          value={!data ? null : findedValue || null}
          options={!data ? [] : data}
          onChange={handleChange}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                error: isError,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
