import * as React from 'react';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';

export const EnterprisePositionControl = withControl<
  Partial<AutocompleteProps<any, any, any, any>>
>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    multiple,
    isError,
  }) => {
    const options = [
      { id: 'director', label: 'Директор/ответственный' },
      { id: 'secretary', label: 'Секретарь' },
      { id: 'employee', label: 'Работник' },
    ];

    const handleChange = (event: any, newValue: any) => {
      if (multiple) {
        setValue(newValue.map((item: any) => item.id) || null);
      } else {
        setValue(newValue?.id || null);
      }
    };

    const getValue = () =>
      multiple
        ? options.filter((item) => value?.indexOf(item.id) > -1)
        : options.find((item) => item.id === value);

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          value={getValue()}
          options={!options ? [] : options}
          onChange={handleChange}
          ListboxComponent={Listbox}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                required,
                readOnly,
                disabled,
                error: isError,
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
          multiple={multiple}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
