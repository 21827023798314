import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import * as api from './api';
import { GetSmsPassModeResponse } from './types';

export const SmsPassModeGate = createGate();

const fxGetSmsPassModeMode = createEffect<void, GetSmsPassModeResponse, Error>().use(
  api.getList
);

export const smsPassModeQuery = createQuery({
  name: 'sms-pass-mode',
  effect: fxGetSmsPassModeMode,
  mapData: ({ result }) =>
    Array.isArray(result)
      ? result.map((item) => ({
          id: item.slug,
          label: item?.title ?? '',
        }))
      : [],
});

cache(smsPassModeQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: SmsPassModeGate.open,
  source: SmsPassModeGate.status,
  filter: (status) => Boolean(status),
  target: smsPassModeQuery.start,
});
