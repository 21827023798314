import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { rentComplexesApi } from '@features/effector-form/controls/rent-complex-controls/api';
import {
  RentComplexPayload,
  RentComplexResponse,
} from '@features/effector-form/controls/rent-complex-controls/types';

export const RentComplexesControlGate = createGate();

const fxGetRentComplexes = createEffect<
  RentComplexPayload,
  RentComplexResponse,
  Error
>().use(rentComplexesApi.getRentComplexes);

export const rentComplexesQuery = createQuery({
  name: 'rentComplex',
  effect: fxGetRentComplexes,
  mapData: ({ result }) =>
    result.areaList.map((item) => ({
      id: item?.guid,
      label: item?.complex?.title || '',
    })),
});

cache(rentComplexesQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: RentComplexesControlGate.state,
  fn: ({ cities }) => {
    if (Array.isArray(cities)) {
      return { filters: { city_ids: cities } };
    }

    if (Number.isInteger(cities)) {
      return { filters: { city_ids: [cities] } };
    }

    return null;
  },
  target: rentComplexesQuery.start,
});
