import {
  FaceOutlined as Face,
  InfoOutlined as Info,
  LocalPhoneOutlined as Phone,
  AssessmentOutlined,
  CollectionsBookmarkOutlined,
  Business,
  ContactsOutlined,
  ViewQuiltOutlined,
  ArchiveOutlined,
  DashboardOutlined,
  LocalParking,
  DirectionsCar,
  SettingsOutlined,
  EventNoteOutlined,
  MiscellaneousServices,
  ShoppingCartOutlined,
  AssignmentTurnedInOutlined,
  RateReviewOutlined,
  SecurityOutlined,
  ContactMail,
  PieChartOutlined,
  PeopleOutlineOutlined,
} from '@mui/icons-material';
import ActiveCommercialServices from '@img/active_commercial_services.svg';
import CommercialServices from '@img/commercial_services.svg';
import DebtorsIcon from '../img/debtors.svg';
import i18n from '../shared/config/i18n';

const { t } = i18n;

export const menuConfig = {
  items: [
    {
      link: 'profile',
      text: t('navMenu.profile'),
      icon: Face,
    },

    /* MANAGEMENT START */
    {
      link: 'dashboard',
      text: t('navMenu.dashboard'),
      icon: DashboardOutlined,
    },
    {
      text: t('navMenu.directories.title'),
      icon: CollectionsBookmarkOutlined,
      nest: true,
      items: [
        {
          link: 'objects-new',
          text: t('navMenu.directories.objects-new'),
        },
        {
          link: 'objects',
          text: t('navMenu.directories.objects'),
        },
        {
          link: 'people',
          text: t('navMenu.directories.people'),
        },
        {
          link: 'rent-objects',
          text: t('navMenu.directories.rent-objects'),
        },
        {
          link: 'users',
          text: t('navMenu.directories.users'),
        },
        {
          link: 'enterprises',
          text: t('navMenu.directories.enterprises'),
        },
        {
          link: 'enterprise-list',
          text: t('navMenu.directories.enterprises-new'),
        },
        {
          link: 'enterprise-contractor',
          text: t('navMenu.directories.enterpriseContractor'),
        },

        {
          link: 'vehicles',
          text: t('navMenu.directories.vehicles'),
        },

        {
          link: 'parking',
          text: t('navMenu.directories.parking'),
        },

        {
          link: 'equipment',
          text: t('navMenu.directories.equipment'),
        },

        {
          link: 'tenants',
          text: t('navMenu.directories.tenants'),
        },
        {
          link: 'activity',
          text: t('navMenu.directories.activity'),
        },
        {
          link: 'accounts',
          text: t('navMenu.directories.personalAccounts'),
        },
      ],
    },
    {
      text: t('navMenu.dispatch.title'),
      icon: EventNoteOutlined,
      nest: true,
      items: [
        {
          link: 'dashboard-climate',
          text: t('MicroclimateDashboard'),
        },
        {
          link: 'tickets-display',
          text: t('navMenu.dispatch.tickets-display'),
        },
        {
          link: 'concierge',
          text: t('navMenu.dispatch.concierge'),
        },
        {
          link: 'tickets',
          text: t('navMenu.dispatch.tickets'),
        },
        {
          link: 'job-schedule',
          text: t('navMenu.dispatch.jobSchedule'),
        },
        {
          link: 'tbo-schedule',
          text: t('navMenu.dispatch.tboSchedule'),
        },
        {
          link: 'planned-work-schedule',
          text: t('navMenu.dispatch.SchedulePPR'),
        },
        {
          link: 'calls',
          text: t('navMenu.dispatch.calls'),
        },
        {
          link: 'inspections',
          text: t('navMenu.settings.Inspections'),
        },
      ],
    },
    {
      text: t('navMenu.engineeringSystems.title'),
      icon: MiscellaneousServices,
      nest: true,
      items: [
        {
          link: 'alarms',
          text: t('navMenu.engineeringSystems.alarms'),
        },
        {
          link: 'counters',
          text: t('navMenu.engineeringSystems.counters'),
        },
        {
          link: 'counters-check',
          text: t('navMenu.engineeringSystems.ReconciliationOfMeteringDevices'),
        },
        {
          link: 'containers',
          text: t('navMenu.engineeringSystems.containers'),
        },
        {
          link: 'doors',
          text: t('navMenu.security.doors'),
        },
      ],
    },
    {
      text: t('navMenu.security.title'),
      icon: SecurityOutlined,
      nest: true,
      items: [
        {
          link: 'security',
          text: t('navMenu.security.security'),
        },
        {
          link: 'intercoms',
          text: t('navMenu.security.intercoms'),
        },
        {
          link: 'barriers',
          text: t('navMenu.security.barriers'),
        },
        {
          link: 'cctv',
          text: t('navMenu.security.cctv'),
        },

        {
          link: 'passes',
          text: t('navMenu.security.passes'),
        },

        {
          link: 'guests',
          text: t('navMenu.security.guests'),
        },
        {
          link: 'events-log',
          text: t('navMenu.security.eventsLog'),
        },
        {
          link: 'stuff',
          text: t('navMenu.security.stuff'),
        },
      ],
    },
    {
      text: t('navMenu.reports.title'),
      icon: PieChartOutlined,
      nest: true,
      items: [
        {
          link: 'reports',
          text: t('navMenu.reports.reportsTicket'),
        },
      ],
    },
    {
      text: t('CommercialServices'),
      icon: CommercialServices,
      activeIcon: ActiveCommercialServices,
      nest: true,
      items: [
        {
          link: 'rent',
          text: t('Booking'),
        },
      ],
    },
    {
      text: t('navMenu.informationCenter.title'),
      icon: Info,
      nest: true,
      items: [
        {
          link: 'news',
          text: t('navMenu.informationCenter.news'),
        },
        {
          text: t('navMenu.informationCenter.polls'),
          link: 'polls',
        },
        {
          link: 'messages',
          text: t('navMenu.informationCenter.messages'),
        },
        {
          text: t('navMenu.informationCenter.appointment'),
          link: 'appointment',
        },
        {
          link: 'voting',
          text: t('navMenu.informationCenter.voting'),
        },
        {
          link: 'company',
          text: t('navMenu.settings.company'),
        },
      ],
    },
    {
      text: t('navMenu.market.title'),
      icon: ShoppingCartOutlined,
      nest: true,
      items: [
        {
          link: 'market-companies',
          text: t('navMenu.market.marketCompanies'),
        },
        {
          link: 'market-offers',
          text: t('navMenu.market.marketOffers'),
        },
      ],
    },
    {
      text: t('navMenu.apartmentAcceptance'),
      link: 'apartment-acceptance',
      icon: AssignmentTurnedInOutlined,
    },
    {
      text: t('navMenu.statistics.title'),
      nest: true,
      icon: AssessmentOutlined,
      items: [
        {
          link: 'statistics',
          text: t('navMenu.statistics.title'),
        },
        {
          link: 'statistics-communication',
          text: t('navMenu.statistics.statisticsCommunication'),
        },
      ],
    },
    {
      text: t('AccessControl'),
      icon: PeopleOutlineOutlined,
      nest: true,
      items: [
        {
          link: 'pass-config',
          text: t('navMenu.settings.PassConfigurator'),
        },
        {
          link: 'access-keys',
          text: t('navMenu.security.accessKeys'),
        },
        {
          link: 'access-groups',
          text: t('navMenu.security.accessGroups'),
        },
        {
          link: 'users-groups',
          text: t('GroupsOfUsers'),
        },
        {
          link: 'access-level',
          text: t('AccessLevel'),
        },
        {
          link: 'schedule',
          text: t('ScheduleTitle'),
        },
        {
          link: 'quarantine',
          text: t('navMenu.security.quarantine'),
        },
        {
          link: 'guests-types',
          text: t('TypesOfGuests'),
        },
      ],
    },
    {
      text: t('navMenu.debtors.title'),
      nest: true,
      icon: DebtorsIcon,
      items: [
        {
          link: 'debtors-list',
          text: t('navMenu.debtors.list'),
        },
        {
          link: 'debtors-notifications',
          text: t('navMenu.debtors.archiveNotofications'),
        },
        {
          link: 'debtors-receipts',
          text: t('navMenu.debtors.archiveReceipts'),
        },
        {
          link: 'debtors-settings',
          text: t('navMenu.debtors.settings'),
        },
        {
          link: 'debtors-access',
          text: t('navMenu.debtors.access'),
        },
      ],
    },
    {
      text: t('navMenu.settings.title'),
      nest: true,
      icon: SettingsOutlined,
      items: [
        {
          link: 'connections',
          text: t('navMenu.dispatch.connections'),
        },
        {
          link: 'user-app',
          text: t('navMenu.settings.userApp'),
        },
        {
          link: 'client-premises',
          text: t('navMenu.settings.clientPremises'),
        },
        {
          link: 'sms-gateway-settings',
          text: t('navMenu.settings.smsGatewaySettings'),
        },
        {
          link: 'roles',
          text: t('navMenu.settings.roles'),
        },
        {
          link: 'works',
          text: t('navMenu.settings.works'),
        },
        {
          link: 'work-flows',
          text: t('navMenu.settings.workFlows'),
        },
        {
          link: 'tickets-types',
          text: t('navMenu.settings.ticketsTypes'),
        },
        {
          link: 'event-service',
          text: t('navMenu.settings.eventService'),
        },
        {
          link: 'parking-lots',
          text: t('navMenu.settings.parkingLots'),
        },
        {
          link: 'devices-hints',
          text: t('navMenu.settings.devicesHints'),
        },
        {
          link: 'planned-works',
          text: t('navMenu.settings.ScheduledMaintenance'),
        },
        {
          link: 'qualifications',
          text: t('navMenu.settings.EmployeeQualifications'),
        },
        {
          link: 'check-lists',
          text: t('navMenu.settings.CheckLists'),
        },
      ],
    },

    /* ENTERPRISES */
    {
      link: 'enterprises-passes',
      text: t('navMenu.enterprises-passes'),
      icon: ContactMail,
    },
    {
      link: 'enterprises-tickets',
      text: t('navMenu.enterprises-tickets'),
      icon: RateReviewOutlined,
    },
    {
      text: t('navMenu.enterprises-stuff'),
      link: 'enterprises-stuff',
      icon: ArchiveOutlined,
    },
    {
      link: 'enterprises-employee',
      text: t('navMenu.enterprises-employee'),
      icon: ContactsOutlined,
    },
    {
      text: t('navMenu.enterprises-contacts'),
      link: 'enterprises-contacts',
      icon: Phone,
    },
    {
      link: 'enterprises-company',
      text: t('navMenu.enterprises-company'),
      icon: Business,
    },
    {
      text: t('navMenu.enterprises-objects'),
      link: 'enterprises-objects',
      icon: ViewQuiltOutlined,
    },
    {
      link: 'enterprises-parking',
      text: t('navMenu.enterprises-parking'),
      icon: LocalParking,
    },
    {
      link: 'enterprises-vehicles',
      text: t('navMenu.enterprises-vehicles'),
      icon: DirectionsCar,
    },
    {
      link: 'enterprises-news',
      text: t('navMenu.enterprises-news'),
      icon: Info,
    },
    {
      link: 'enterprises-events',
      text: t('navMenu.enterprises-events'),
      icon: EventNoteOutlined,
    },
  ],
};

export const visibilityConfig = [
  /* MANAGEMENT */
  'dashboard',
  'dashboard-climate',
  'people',
  'users',
  'roles',
  'company',
  'news',
  'voting',
  'objects',
  'objects-new',
  'counters',
  'channels',
  'tickets-display',
  'tickets',
  'intercoms',
  'alarms',
  'cctv',
  'guests',
  'appointment',
  'barriers',
  'vehicles',
  'calls',
  'connections',
  'user-app',
  'sms-gateway-settings',
  'job-schedule',
  'tbo-schedule',
  'concierge',
  'tenants',
  'rent-objects',
  'enterprises',
  'passes',
  'quarantine',
  'statistics',
  'statistics-communication',
  'debtors-list',
  'debtors-notifications',
  'debtors-receipts',
  'debtors-settings',
  'debtors-access',
  'market-companies',
  'market-offers',
  'messages',
  'apartment-acceptance',
  'parking',
  'parking-lots',
  'events-log',
  'polls',
  'stuff',
  'journal-users-actions',
  'enterprise-contractor',
  'security',
  'containers',
  'doors',
  'schedule',
  'access-keys',
  'dashboard',
  'equipment',
  'works',
  'event-service',
  'tickets-types',
  'devices-hints',
  'access-groups',
  'reports',
  'activity',
  'counters-check',
  'planned-works',
  'users-groups',
  'access-level',
  'pass-config',
  'qualifications',
  'planned-work-schedule',
  'work-flows',
  'accounts',
  'check-lists',
  'inspections',
  'client-premises',
  'rent',
  'guests-types',
  'enterprise-list',

  /* ENTERPRISES */
  'enterprises-company',
  'enterprises-employee',
  'enterprises-passes',
  'enterprises-contacts',
  'enterprises-vehicles',
  'enterprises-tickets',
  'enterprises-events',
  'enterprises-stuff',
  'enterprises-parking',
  'enterprises-objects',
  'enterprises-news',
];
