import ru from 'date-fns/locale/ru';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { TimeField, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { withControl } from '@features/effector-form/hoc/withControl';

export const TimeControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled, isError }) => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <FormControl required={required && !readOnly} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <TimeField
          value={value}
          onChange={(newValue) => setValue(newValue)}
          InputProps={{
            readOnly,
            disabled,
            error: isError,
          }}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </LocalizationProvider>
  )
);
