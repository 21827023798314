import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { BuildingControlGate, buildingQuery } from './model';
import { BuildingControlProps, BuildingItem } from './types';

export const BuildingsFilterControl = withControl<BuildingControlProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    complexes,
    placeholder,
    isError,
  }) => {
    useGate(BuildingControlGate);

    const { data, pending } = useUnit(buildingQuery);
    const complexesSet = new Set(complexes);

    const filterBuildings = () => {
      if (!data) return [];
      if (complexes?.length && data) {
        return data.filter((item) => complexesSet.has(item.complexId));
      }
      return data;
    };

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          multiple
          loading={pending}
          value={value}
          options={filterBuildings()}
          onChange={(event: any, newValue: BuildingItem[]) => {
            setValue(newValue.map((item) => item));
          }}
          getOptionLabel={(option) => option.title}
          ListboxComponent={Listbox}
          disableClearable
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                placeholder,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
