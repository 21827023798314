import { SyntheticEvent, useMemo } from 'react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { withControl } from '@features/effector-form/hoc/withControl';

type Props = {
  isOpen?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  options: { id: number | string; label: string }[] | [];
  label?: string;
  onOpen?: () => void;
  onClose?: () => void;
};

export const AsyncAutocompleteControl = withControl<Props>(
  ({
    isLoading,
    value: id,
    options,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    isError,
    ...props
  }) => {
    const value = useMemo(
      () => (options.length > 0 ? options.find((list) => list.id === id) : null),
      [options, id]
    );

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          open={props.isOpen}
          onOpen={props.onOpen}
          onClose={props.onClose}
          disabled={readOnly || disabled}
          value={value}
          options={options}
          loading={isLoading}
          onChange={(e: SyntheticEvent, newValue: any) => {
            setValue(newValue?.id ?? '');
          }}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props?.label}
              InputProps={{
                ...params.InputProps,
                error: isError,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
