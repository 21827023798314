import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  CircularProgress,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { TicketOriginControlGate, ticketOriginsQuery } from './model';

export const TicketOriginControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled, isError }) => {
    useGate(TicketOriginControlGate);

    const { data, pending } = useUnit(ticketOriginsQuery);

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          loading={pending}
          value={!data ? null : data?.find((item) => item.id === value)}
          options={!data ? [] : data}
          onChange={(event: any, newValue: any) => {
            setValue(newValue?.id || null);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
