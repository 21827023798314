import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { EnterpriseControlGate, enterpriseQuery } from './model';
import { Enterprise } from './types';

export const EnterpriseControl = withControl<
  Partial<AutocompleteProps<any, any, any, any>>
>(
  ({
    label,
    value,
    setValue,
    error,
    required,
    readOnly,
    disabled,
    multiple,
    isError,
    ...props
  }) => {
    useGate(EnterpriseControlGate);

    const { data, pending } = useUnit(enterpriseQuery);

    const handleChange = (event: any, newValue: any) => {
      if (multiple) {
        setValue(newValue.map((item: Enterprise) => item.id) || null);
      } else {
        setValue(newValue?.id || null);
      }
    };

    const getValue = () => {
      if (!data && multiple) return [];
      if (!data && !multiple) return null;

      return multiple
        ? data?.filter((item) => value?.indexOf(item.id) > -1)
        : data?.find((item) => item.id === value);
    };

    return (
      <FormControl>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          {...props}
          loading={pending}
          value={getValue()}
          options={!data ? [] : data}
          onChange={handleChange}
          ListboxComponent={Listbox}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                required,
                readOnly,
                disabled,
                error: isError,
                endAdornment:
                  pending && !readOnly ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    params.InputProps.endAdornment
                  ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
          multiple={multiple}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
