import { createEffect, sample, createStore } from 'effector';
import { createGate } from 'effector-react';
import { signout } from '@features/common';
import { propertyTypesApi } from '@features/effector-form/controls/property-type-control/api';
import {
  PropertyTypePayload,
  PropertyTypeResponse,
  Props,
  PropertyTypeItem,
} from '@features/effector-form/controls/property-type-control/types';

export const PropertyTypesControlGate = createGate<Props>();

export const $data = createStore<{ [key: string]: PropertyTypeItem[] }>({});
export const $pending = createStore<boolean>(false);

const fxGetPropertyTypes = createEffect<
  PropertyTypePayload,
  PropertyTypeResponse,
  Error
>().use(propertyTypesApi.getPropertyTypes);

$data
  .on(fxGetPropertyTypes.done, (state, { params, result }) => {
    if (!Array.isArray(result?.types)) {
      return state;
    }

    const withClientType = params?.with_client_type ? `${params.with_client_type}-` : '';
    const mode = params?.mode ? `${params.mode}-` : '';
    const parentAreaId = params?.parent_area_id ? `${params.parent_area_id}` : 'default';
    const key = `${withClientType}${mode}${parentAreaId}`;

    const objects = {
      [key]: result.types.map((type) => ({
        id: type.id,
        label: type?.title ?? '',
        name: type?.parent_type_title ? type.parent_type_title : type?.name ?? '',
        picture: type?.picture ?? null,
      })),
    };

    return { ...state, ...objects };
  })
  .reset(signout);

$pending.on(fxGetPropertyTypes.pending, (_, pending) => pending).reset(signout);

sample({
  clock: [PropertyTypesControlGate.state, PropertyTypesControlGate.status],
  source: {
    status: PropertyTypesControlGate.status,
    state: PropertyTypesControlGate.state,
  },
  filter: ({ status, state: { onlyActual, parentAreaId } }) => {
    if (status) {
      return onlyActual ? Boolean(parentAreaId) : true;
    }

    return false;
  },
  fn: ({ state: { withClientType, mode, onlyActual, parentAreaId } }) => {
    const payload: PropertyTypePayload = {};

    if (withClientType) {
      payload.with_client_type = Number(withClientType);
    }

    if (mode) {
      payload.mode = mode;
    }

    if (onlyActual && parentAreaId) {
      payload.only_actual = Number(onlyActual);
      payload.parent_area_id = parentAreaId;
    }

    return payload;
  },
  target: fxGetPropertyTypes,
});
