import { createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { signout } from '@features/common';
import { rentBuildingsApi } from '@features/effector-form/controls/rent-building-controls/api';
import {
  RentBuildingsPayload,
  RentBuildingsResponse,
  RentBuilding,
} from '@features/effector-form/controls/rent-building-controls/types';

export const RentBuildingsControlGate = createGate();

export const $data = createStore<{ [key: string]: RentBuilding[] }>({});
export const $pending = createStore<boolean>(false);

const fxGetRentBuildings = createEffect<
  RentBuildingsPayload,
  RentBuildingsResponse,
  Error
>().use(rentBuildingsApi.getRentBuildings);

$data
  .on(fxGetRentBuildings.done, (state, { params, result }) => {
    if (!Array.isArray(result?.areaList)) {
      return state;
    }

    const objects = {
      [params.parent_guids.join('-')]: result.areaList.map((item) => ({
        id: item?.guid,
        label: item?.building?.title || '',
      })),
    };

    return { ...state, ...objects };
  })
  .reset(signout);

$pending.on(fxGetRentBuildings.pending, (_, pending) => pending).reset(signout);

sample({
  clock: RentBuildingsControlGate.state,
  filter: ({ complexes }) =>
    Array.isArray(complexes) ? complexes.length > 0 : Boolean(complexes),
  fn: ({ complexes }) => {
    if (Array.isArray(complexes)) {
      return { parent_guids: complexes };
    }

    if (typeof complexes === 'string') {
      return { parent_guids: [complexes] };
    }

    return null;
  },
  target: fxGetRentBuildings,
});
