import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  RentFloor,
  RentFloorsControlProps,
} from '@features/effector-form/controls/rent-floor-controls/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { RentFloorsControlGate, rentFloorsQuery } from '../model';

export const RentFloorsControl = withControl<RentFloorsControlProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    buildings = [],
    isError,
  }) => {
    useGate(RentFloorsControlGate, { buildings });

    const { data, pending } = useUnit(rentFloorsQuery);

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          multiple
          loading={pending}
          value={!data ? [] : data.filter((item) => value.indexOf(item.id) > -1)}
          options={data && buildings.length > 0 ? data : []}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue.map((item: RentFloor) => item.id));
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
