import { createEffect, sample, createStore } from 'effector';
import { createGate } from 'effector-react';
import { signout } from '@features/common';
import { rentObjectsApi } from '@features/effector-form/controls/rent-objects/api';
import {
  RentObjectsPayload,
  RentObjectsResponse,
  Properties,
} from '@features/effector-form/controls/rent-objects/types';

export const RentObjectsControlGate = createGate<{
  propertyType: string;
  object: string | string[];
}>();

export const $data = createStore<Properties>({});
export const $pending = createStore<boolean>(false);

const fxGetRentObjects = createEffect<
  RentObjectsPayload,
  RentObjectsResponse,
  Error
>().use(rentObjectsApi.getRentObjects);

$data
  .on(fxGetRentObjects.done, (state, { params, result }) => {
    if (!Array.isArray(result?.areaList)) {
      return state;
    }

    const parentGuids = params.parent_guids.join('-');
    const areaTypes = params.area_types.join('-');

    const objects = {
      [`${parentGuids}-${areaTypes}`]: result.areaList.map((object) => ({
        id: object.guid,
        label: object.title ?? '',
      })),
    };

    return { ...state, ...objects };
  })
  .reset(signout);

$pending.on(fxGetRentObjects.pending, (_, pending) => pending).reset(signout);

sample({
  clock: RentObjectsControlGate.state,
  filter: ({ propertyType, object }) => Boolean(propertyType && object),
  fn: ({ propertyType, object }) => ({
    parent_guids: Array.isArray(object) ? object : [object],
    area_types: [propertyType],
  }),
  target: fxGetRentObjects,
});
