import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import * as api from './api';
import { GetEnterpriseConfirmationModeResponse } from './types';

export const EnterpriseConfirmationModeGate = createGate();

const fxGetEnterpriseConfirmationMode = createEffect<
  void,
  GetEnterpriseConfirmationModeResponse,
  Error
>().use(api.getList);

export const enterpriseConfirmationModeQuery = createQuery({
  name: 'enterprise-confirmation-mode',
  effect: fxGetEnterpriseConfirmationMode,
  mapData: ({ result }) =>
    Array.isArray(result?.items)
      ? result.items.map((item) => ({
          id: item.id,
          slug: item?.slug ?? '',
          label: item?.title ?? '',
        }))
      : [],
});

cache(enterpriseConfirmationModeQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: EnterpriseConfirmationModeGate.open,
  source: EnterpriseConfirmationModeGate.status,
  filter: (status) => Boolean(status),
  target: enterpriseConfirmationModeQuery.start,
});
