import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  Complex,
  ComplexesControlType,
} from '@features/effector-form/controls/complexes/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { ComplexesControlGate, complexesQuery } from './model';

export const ComplexesControl = withControl<ComplexesControlType>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    placeholder,
    isError,
  }) => {
    useGate(ComplexesControlGate);

    const { data, pending } = useUnit(complexesQuery);

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          multiple
          loading={pending}
          value={!data ? [] : data.filter((item) => value.indexOf(item.id) > -1)}
          options={!data ? [] : data}
          onChange={(event: any, newValue: any) => {
            setValue(newValue.map((item: Complex) => item.id));
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                placeholder,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
