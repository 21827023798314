import { useCallback } from 'react';
import { useIMask } from 'react-imask';
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { shareToString } from '@features/effector-form/controls/objectShare/lib/shareToString';
import { stringToShare } from '@features/effector-form/controls/objectShare/lib/stringToShare';
import { withControl } from '@features/effector-form/hoc/withControl';

export const ObjectShareControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled, isError }) => {
    const innerValue: { numerator: number; denominator: number } = value
      ? value
      : { numerator: '', denominator: '' };
    const textValue = shareToString(innerValue);

    const handleAccept = useCallback(
      (newValue: string) => {
        if (newValue !== value) {
          setValue(stringToShare(newValue));
        }
      },
      [value]
    );

    const handleComplete = useCallback(
      (newValue: string) => {
        setValue(stringToShare(newValue));
      },
      [value]
    );

    const { ref } = useIMask(
      { mask: '0/0' },
      {
        onAccept: handleAccept,
        onComplete: handleComplete,
      }
    );

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <OutlinedInput
          inputRef={ref}
          value={textValue}
          readOnly={readOnly}
          disabled={disabled}
          error={isError}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
