import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { RentStatusesControlGate, rentStatusesQuery } from '../model';
import { StatusProps } from '../types';

export const RentStatusControl = withControl<StatusProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    mode = 'registry',
    isError,
  }) => {
    useGate(RentStatusesControlGate, { mode });

    const { data, pending } = useUnit(rentStatusesQuery);

    const val = value !== '' ? data?.find((item) => item.id === value) : null;

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          loading={pending}
          value={!data ? null : val || null}
          options={!data ? [] : data}
          onChange={(event: any, newValue: any) => {
            setValue(newValue?.id || null);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
