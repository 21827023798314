import { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import { withControl } from '@features/effector-form/hoc/withControl';

type Props = {
  helperText?: string;
};

export const CheckboxControl = withControl<Props>(
  ({ value, setValue, label, readOnly, disabled, helperText }) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.checked);
    };

    return (
      <FormControl>
        <FormControlLabel
          control={<Checkbox checked={Boolean(value)} onChange={handleChange} />}
          label={label}
          disabled={readOnly || disabled}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);
