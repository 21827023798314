import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  PropertyType,
  Props,
  PropertyTypeItem,
} from '@features/effector-form/controls/property-type-control/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { PropertyTypesControlGate, $data, $pending } from '../model';

export const PropertyTypesControl = withControl<Props>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    withClientType = false,
    disableClearable = false,
    sx = {},
    mode,
    onlyActual = false,
    parentAreaId = null,
    excludeOptions = [],
    sxAutocomplete = {},
    isError,
  }) => {
    useGate(PropertyTypesControlGate, { withClientType, mode, onlyActual, parentAreaId });
    const [data, pending] = useUnit([$data, $pending]);

    const withClientTypeKey = withClientType ? `${Number(withClientType)}-` : '';
    const modeKey = mode ? `${mode}-` : '';
    const parentAreaIdKey = parentAreaId ? `${parentAreaId}` : 'default';
    const key = `${withClientTypeKey}${modeKey}${parentAreaIdKey}`;
    const options = data[key] ?? [];
    const list = onlyActual && !parentAreaId ? [] : options;

    const handleFilterOptions = (
      opts: PropertyTypeItem[],
      { inputValue }: { inputValue: string }
    ) => {
      const currentOptions = excludeOptions.length
        ? opts.reduce(
            (acc: PropertyTypeItem[], option) =>
              !excludeOptions.includes(option.id) ? [...acc, option] : acc,
            []
          )
        : opts;

      if (inputValue) {
        return currentOptions.filter((opt) =>
          opt?.label?.toUpperCase().includes(inputValue.toUpperCase())
        );
      }

      return currentOptions;
    };

    return (
      <FormControl required={required} disabled={disabled} sx={sx}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          filterOptions={handleFilterOptions}
          disableClearable={disableClearable}
          multiple
          loading={pending}
          value={
            !list
              ? []
              : list.filter(
                  (item) =>
                    value
                      .map((i: PropertyType) => (typeof i === 'object' ? i.id : i))
                      .indexOf(item.id) > -1
                )
          }
          options={list}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue.map((item: PropertyType) => item));
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
          sx={sxAutocomplete}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
