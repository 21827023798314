import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  RentBuilding,
  RentBuildingsProps,
} from '@features/effector-form/controls/rent-building-controls/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { RentBuildingsControlGate, $data, $pending } from '../model';

export const RentBuildingsControl = withControl<RentBuildingsProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    complexes = [],
    excludeOptions = [],
    isError,
  }) => {
    useGate(RentBuildingsControlGate, { complexes });
    const [data, pending] = useUnit([$data, $pending]);

    const key = complexes.join('-');
    const options = data[key] ?? [];

    const handleFilterOptions = (
      opts: RentBuilding[],
      { inputValue }: { inputValue: string }
    ) => {
      const currentOptions = excludeOptions.length
        ? opts.reduce(
            (acc: RentBuilding[], option) =>
              !excludeOptions.includes(option.id) ? [...acc, option] : acc,
            []
          )
        : opts;

      if (inputValue) {
        return currentOptions.filter((opt) =>
          opt?.label?.toUpperCase().includes(inputValue.toUpperCase())
        );
      }

      return currentOptions;
    };

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          multiple
          loading={pending}
          filterOptions={handleFilterOptions}
          value={!options ? [] : options.filter((item) => value.indexOf(item.id) > -1)}
          options={options && complexes.length > 0 ? options : []}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue.map((item: RentBuilding) => item.id));
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
