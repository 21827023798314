import * as React from 'react';
import { useGate, useUnit } from 'effector-react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  ApartmentControlGate,
  apartmentsQuery,
} from '@features/effector-form/controls/apartment/model';
import { ApartmentControlType } from '@features/effector-form/controls/apartment/types';
import { withControl } from '@features/effector-form/hoc/withControl';

export const ApartmentControl = withControl<ApartmentControlType>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    complex,
    building,
    isError,
  }) => {
    useGate(ApartmentControlGate, { complex, building });

    const { data, pending } = useUnit(apartmentsQuery);

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          loading={pending}
          value={!data ? null : data?.find((item) => item.id === value)}
          options={!data ? [] : data}
          onChange={(event: any, newValue: any) => {
            setValue(newValue.id);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                error: isError,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
