import ru from 'date-fns/locale/ru';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { withControl } from '@features/effector-form/hoc/withControl';
import { theme } from '@shared/theme';

type Props = {
  sx?: object;
  sxForm?: object;
};

export const DateRangeControl = withControl<Props>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    sx = {},
    sxForm = {},
    isError,
  }) => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <FormControl required={required} disabled={disabled} sx={sxForm}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <SingleInputDateRangeField
          value={value}
          onChange={(newValue) => setValue(newValue)}
          InputProps={{
            readOnly,
            disabled,
            error: isError,
            endAdornment: <CalendarToday sx={{ color: theme.palette.action.active }} />,
          }}
          readOnly={readOnly}
          disabled={disabled}
          sx={sx}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </LocalizationProvider>
  )
);
